import {
  CustomToolInputFieldTypesEnum,
  type BlockDataBase,
  type CustomToolInputField,
  type FieldsConfig,
  type UserInputDictType
} from "@toolflow/shared";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import useAvailableTestFields from "./useAvailableTestFields";

export default function useTestFields(
  id: string,
  fields: FieldsConfig,
  data: BlockDataBase
) {
  const availableBlockFields = useAvailableTestFields(id, fields, data);

  const [customFields, setCustomFields] = useState<CustomToolInputField[]>([]);
  const [userInput, setUserInputState] = useState<UserInputDictType>({});
  const [inputFields, setInputFields] = useState<string[]>([]);

  useEffect(() => {
    const tempFields = availableBlockFields.reduce(
      (acc, value) => {
        acc.add(value.fieldLabel);
        return acc;
      },
      new Set([]) as Set<string>
    );

    setInputFields([...tempFields]);
    setUserInputState(() => ({
      ...tempFields.values().reduce((acc, v) => {
        acc[v] = "";
        return acc;
      }, {} as UserInputDictType)
    }));
  }, [id, availableBlockFields]);

  function addCustomField(name: string) {
    setCustomFields((prev) => {
      // Don't add existing customFields
      if (prev.some((p) => p.name === name)) return prev;

      return [
        ...prev,
        {
          name,
          id: uuidv4(),
          type: CustomToolInputFieldTypesEnum.TEXTFIELD
        }
      ];
    });
  }

  /**
   * Updated setUserInput function that accepts a single UserInputDictType parameter
   * and updates both flat keys and nested structures.
   */
  const setUserInput = (newInput: UserInputDictType) => {
    setUserInputState((prevUserInput) => {
      const updatedUserInput = { ...prevUserInput };

      Object.keys(newInput).forEach((key) => {
        const value = newInput[key];
        const keyParts = key.split(".");
        const topLevelKey = keyParts[0];
        const isNested = keyParts.length > 1;

        if (isNested) {
          // Update the nested structure
          if (
            typeof updatedUserInput[topLevelKey] !== "object" ||
            updatedUserInput[topLevelKey] === null
          ) {
            updatedUserInput[topLevelKey] = {};
          }

          let currentLevel: $TSAllowedAny = updatedUserInput[topLevelKey];

          // Traverse to the correct depth
          for (let i = 1; i < keyParts.length - 1; i++) {
            const part = keyParts[i];
            if (
              typeof currentLevel[part] !== "object" ||
              currentLevel[part] === null
            ) {
              currentLevel[part] = {};
            }
            currentLevel = currentLevel[part];
          }

          // Set the value at the deepest level
          currentLevel[keyParts[keyParts.length - 1]] = value;

          // Also update the flat key
          updatedUserInput[key] = value;
          addCustomField(topLevelKey);
        } else {
          // Handle top-level keys
          updatedUserInput[key] = value;
        }
      });

      return updatedUserInput;
    });
  };

  return {
    userInput,
    inputFields,
    customFields,
    availableBlockFields,
    setUserInput
  };
}
